import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cls from 'classnames';
import { formatManufacturers } from '../formatters/manufacturer';
import Link from '../components/Link';
import Layout from '../components/Layout';
import Meta from '../components/Meta';
import Section from '../components/Section';
import ContentWithSidebar from '../components/ContentWithSidebar';
import AspectRatioImage from '../components/AspectRatioImage';
import styles from './manufacturers.module.scss';

const ManufacturersPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { template: { eq: "manufacturer" } } }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          ...ManufacturerFragment
          excerpt(pruneLength: 120)
        }
      }
    }
  `);
  const title = 'Manufacturers';

  return (
    <Layout>
      <Meta title={title} />
      <Section>
        <ContentWithSidebar>
          <h1 className="title">{title}</h1>
          {formatManufacturers(data).map(manufacturer => (
            <div key={manufacturer.id} className="box">
              <div className="columns is-variable is-4">
                {manufacturer.image && (
                  <div className="column is-one-third">
                    <AspectRatioImage ratio={3 / 2} src={manufacturer.image} />
                  </div>
                )}
                <div className="column is-two-thirds">
                  <div>
                    <div className={styles.heading}>
                      <h2 className={cls('is-size-5', styles.title)}>
                        <Link to={manufacturer.path}>{manufacturer.title}</Link>
                      </h2>
                    </div>
                    <p>{manufacturer.excerpt}</p>
                    <div>
                      <Link className="button is-small" to={manufacturer.path}>
                        Read more →
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ContentWithSidebar>
      </Section>
    </Layout>
  );
};

export default ManufacturersPage;
